import { styled, keyframes } from 'styled-components';
import { Swiper as SwiperBase } from 'swiper/react';

const grow = keyframes`
  from {
    transform: translateX(-88%)
  }

  to {
    transform: translateX(0);
  }
`;

export const SliderContainer = styled.div<{ $isVisible: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  transition: opacity 0.3s;
`;

/**
 * Calculates the required offset from center for the arrows.
 *
 * ((non active slides * width + margin) + active width) / 2 (offset by half) + margin
 * @param numberOfSlides
 * @returns
 */
const calculateButtonOffset = (numberOfSlides: number) =>
  ((numberOfSlides - 1) * 14 + 58) / 2 + 24;

export const Swiper = styled(SwiperBase)<{
  $timeIntervalSeconds: number;
  $numberOfSlides: number;
}>`
  padding-bottom: 31px;
  max-width: 100%;
  display: flex;
  justify-content: center;

  .swiper-button-prev,
  .swiper-button-next {
    height: 16px;
    width: 16px;
    top: auto;
    bottom: 8px;
    left: auto;
    right: auto;
    z-index: 11;
  }
  .swiper-button-prev::after,
  .swiper-button-next::after {
    font-size: 14px;
    color: ${({ theme }) => theme.colors.gray600};
  }
  .swiper-button-prev {
    transform: translateX(
      ${({ $numberOfSlides }) => `-${calculateButtonOffset($numberOfSlides)}px`}
    );
  }
  .swiper-button-next {
    transform: translateX(
      ${({ $numberOfSlides }) => `${calculateButtonOffset($numberOfSlides)}px`}
    );
  }

  .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
    background: ${({ theme }) => theme.colors.gray200};
    border-radius: 3px;
    opacity: 1;
    position: relative;
    overflow: hidden;
    transition: width 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  }

  .swiper-pagination-bullet.swiper-pagination-bullet-active {
    width: 50px;

    &::after {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0%;
      left: 0%;
      background: ${({ theme }) => theme.colors.machineGreen500};
      border-radius: 3px;
      transform: translateX(-88%);
      animation: ${grow}
        ${({ $timeIntervalSeconds }) => `${$timeIntervalSeconds}s`} linear 0.3s
        forwards;
      transform-origin: 100% -50%;
    }
  }
`;

export const ContentContainer = styled.div`
  text-align: center;
  max-width: 897px;
  margin-bottom: 32px;
`;

export const CTAContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  max-width: 897px;
  margin-bottom: 32px;

  @media ${({ theme }) => theme.breakpoints.medium} {
    flex-direction: row;
    gap: 20px;
  }
`;
