import { ButtonVariants } from '../Button/Button.styles';
import Typography from '../Typography';
import Button from '../Button';
import {
  ContentContainer,
  CTAContainer,
  SliderContainer,
} from './Carousel.styles';
import { useSwiperSlide } from 'swiper/react';

export type SlideProps = {
  id: string;
  children: React.ReactNode;
  title?: string;
  description?: string;
  ctas?: {
    label: string;
    link: string;
    variant: ButtonVariants;
  }[];
};

function Slide(props: SlideProps) {
  const { children, title, description, ctas } = props;
  const swiperSlide = useSwiperSlide();

  return (
    <SliderContainer $isVisible={swiperSlide.isVisible}>
      <div
        style={{
          marginBottom: '30px',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {children}
      </div>
      <ContentContainer>
        {title && (
          <Typography variant="heading4" style={{ marginBottom: '16px' }}>
            {title}
          </Typography>
        )}
        {description && (
          <Typography variant="bodyStandard">{description}</Typography>
        )}
      </ContentContainer>

      {ctas && (
        <CTAContainer>
          {ctas.map((cta) => (
            <Button key={cta.label} variant={cta.variant} href={cta.link}>
              {cta.label}
            </Button>
          ))}
        </CTAContainer>
      )}
    </SliderContainer>
  );
}

export default Slide;
