import { SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation, EffectFade } from 'swiper/modules';
import { SliderContainer, ContentContainer, Swiper } from './Carousel.styles';
import Slide, { SlideProps } from './Slide';
import Typography from '../Typography';

import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

export type CarouselProps = {
  title: string;
  dynamicHeight?: boolean;
  subTitle?: string;
  slides: SlideProps[];
  timeIntervalSeconds?: number;
};

function Carousel(props: CarouselProps) {
  const { dynamicHeight, title, subTitle, slides } = props;
  const timeIntervalSeconds = props.timeIntervalSeconds || 5;

  return (
    <SliderContainer $isVisible>
      <ContentContainer>
        {subTitle && (
          <Typography color="machineGreen500" variant="eyebrowLarge">
            {subTitle}
          </Typography>
        )}
        <Typography variant="heading2" style={{ marginBottom: '8px' }}>
          {title}
        </Typography>
      </ContentContainer>

      <Swiper
        slidesPerView={1}
        effect="fade"
        centeredSlides={true}
        autoHeight={dynamicHeight}
        autoplay={{
          delay: timeIntervalSeconds * 1000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[EffectFade, Autoplay, Pagination, Navigation]}
        loop
        $timeIntervalSeconds={timeIntervalSeconds}
        $numberOfSlides={slides.length}
      >
        {props.slides.map((slideProps) => (
          <SwiperSlide key={slideProps.id}>
            <Slide {...slideProps} />
          </SwiperSlide>
        ))}
      </Swiper>
    </SliderContainer>
  );
}

export default Carousel;
