import Link from 'styleguide/src/components/Link';
import { HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';

type LinkProps = {
  children: ReactNode;
  href: string;
  className?: string;
  attributes?: Omit<HTMLAttributes<HTMLElement>, 'color'>;
  isFaq?: boolean;
};

const StyledLink = styled(Link)<{ isFaq?: boolean }>`
  border-style: ${(props) => (props.isFaq ? `solid` : 'none')};
  border-width: ${(props) => (props.isFaq ? `0 0 1px 0` : 'none')};
  border-color: ${(props) =>
    props.isFaq ? props.theme.colors.gray400 : 'white'};
`;

export default function BuilderLink({
  children,
  href,
  className,
  isFaq,
  attributes,
}: LinkProps) {
  return (
    <StyledLink
      className={`${className || ''} ${attributes?.className || ''}`}
      href={href}
      isFaq={isFaq}
      external={true}
    >
      {children}
    </StyledLink>
  );
}
