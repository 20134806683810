import { Builder } from '@builder.io/react';
import theme from 'styleguide/src/themes/theme';
import { buttonVariants } from 'styleguide/src/components/Button';
import { pageModel } from 'types/pageModels';
import Button from './Button';
import Text from './Text';
import SvgIcon from './SvgIcon';
import Link from './Link';
import Carousel from './Carousel';

const BaseComponentInputProps = [
  {
    name: 'variant',
    type: 'string',
    defaultValue: 'inherit',
    enum: ['inherit', 'primary', 'secondary'],
  },
];

const BaseTextComponentProps = [
  {
    name: 'color',
    type: 'string',
    enum: Object.keys(theme.colors),
    defaultValue: 'black',
  },
  {
    name: 'align',
    type: 'string',
    enum: ['left', 'right', 'center', 'justify'],
    defaultValue: 'left',
  },
  { name: 'fontSize', type: 'number' },
  {
    name: 'fontWeight',
    type: 'string',
    enum: ['300', '400', '500', '600', '700', '800'],
  },
  { name: 'lineHeight', type: 'string' },
  { name: 'letterSpacing', type: 'string' },
];

Builder.registerComponent(Button, {
  name: 'Button',
  noWrap: true,
  inputs: [
    { name: 'buttonText', type: 'string', defaultValue: 'Button text' },
    { name: 'buttonUrl', type: 'string', required: false },
    {
      name: 'variant',
      type: 'string',
      enum: Array.from(buttonVariants),
      defaultValue: 'primary',
    },
    {
      name: 'buttonUrlScrollToTop',
      type: 'boolean',
      defaultValue: true,
      helperText:
        'Controls whether to scroll to top of the page when button has a url to another page.',
    },
  ],
  override: true,
});

Builder.registerComponent(SvgIcon, {
  name: 'SvgIcon',
  noWrap: true,
  inputs: [
    { name: 'icon', type: 'code' },
    { name: 'size', type: 'string', defaultValue: '64px' },
    { name: 'label', type: 'string' },
    {
      name: 'unthemed',
      type: 'boolean',
      helperText:
        'By default we will modify the SVG to match the theme. Turn this off to not modify the theme.',
      defaultValue: false,
    },
    ...BaseComponentInputProps,
  ],
});

Builder.registerComponent(Text, {
  models: [pageModel],
  name: 'Text',
  noWrap: true,
  inputs: [
    { name: 'text', type: 'longText', defaultValue: 'Text goes here...' },
    {
      name: 'variant',
      type: 'string',
      enum: [
        'bodyLarge',
        'bodyStandard',
        'bodySmall',
        'ctaStandard',
        'ctaSmall',
        'eyebrowSmall',
        'eyebrowLarge',
      ],
      defaultValue: 'bodyLarge',
    },
    {
      name: 'tagName',
      type: 'string',
      enum: ['p', 'span', 'div', 'li'],
      defaultValue: 'p',
    },
    ...BaseTextComponentProps,
  ],
  override: true,
});

Builder.registerComponent(Text, {
  models: [pageModel],
  name: 'Heading',
  noWrap: true,
  inputs: [
    {
      name: 'text',
      type: 'longText',
      defaultValue: 'Some Heading Typography...',
    },
    {
      name: 'variant',
      type: 'string',
      enum: [
        'heading1',
        'heading2',
        'heading3',
        'heading4',
        'heading5',
        'heading6',
      ],
      defaultValue: 'heading1',
    },
    {
      name: 'tagName',
      type: 'string',
      enum: ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
      defaultValue: 'p',
    },
    ...BaseTextComponentProps,
  ],
});

Builder.registerComponent(Link, {
  models: [pageModel],
  name: 'Link',
  noWrap: true,
  inputs: [
    {
      name: 'href',
      type: 'longText',
      defaultValue: 'http://dummy.com',
    },
    {
      name: 'children',
      type: 'string',
      defaultValue: 'link text',
    },
    {
      name: 'external',
      type: 'boolean',
      defaultValue: 'false',
    },
    {
      name: 'isFaq',
      type: 'boolean',
      defaultValue: 'false',
    },
  ],
});

Builder.registerComponent(Carousel, {
  models: [pageModel],
  name: 'Carousel',
  noWrap: true,
  inputs: [
    {
      name: 'title',
      type: 'string',
      defaultValue: 'Carousel Title',
      required: true,
    },
    {
      name: 'subtitle',
      type: 'string',
    },
    {
      name: 'slideDuration',
      type: 'number',
      defaultValue: 5,
      helperText: 'The length of time (in seconds) a slide will stay visible.',
      regex: {
        pattern: '^[0-9]+$',
        options: 'g',
        message: 'You must enter a valid number.',
      },
    },
    {
      name: 'dynamicHeight',
      type: 'boolean',
      defaultValue: false,
      helperText:
        'Controls whether the carousel will adjust its height based on the content.',
    },
    {
      name: 'slides',
      type: 'list',
      defaultValue: [
        {
          title: 'Slide Title',
          description: 'Slide Description',
          callToAction: [
            {
              label: 'Button Text',
              link: 'https://example.com',
              variant: 'primary',
            },
          ],
        },
      ],
      subFields: [
        {
          name: 'title',
          type: 'string',
        },
        {
          name: 'description',
          type: 'string',
        },
        {
          name: 'video',
          type: 'file',
          allowedFileTypes: ['mp4', 'webm'],
        },
        {
          name: 'image',
          type: 'file',
          allowedFileTypes: ['jpg', 'jpeg', 'png'],
        },
        {
          name: 'alt',
          type: 'string',
          defaultValue: 'Media Alt Tag',
          required: true,
        },
        {
          name: 'callToAction',
          type: 'list',
          defaultValue: [
            {
              label: 'Button Text',
              link: 'https://example.com',
              variant: 'primary',
            },
          ],
          subFields: [
            {
              name: 'label',
              type: 'string',
              defaultValue: 'Button Text',
              required: true,
            },
            {
              name: 'link',
              type: 'string',
              required: true,
              defaultValue: 'https://example.com',
            },
            {
              name: 'variant',
              type: 'string',
              enum: Array.from(buttonVariants),
              defaultValue: 'primary',
            },
          ],
        },
      ],
      required: true,
    },
  ],
});
