import {
  useMemo,
  useCallback,
  createElement,
  ReactHTML,
  ComponentType,
  ComponentProps,
  ReactElement,
  JSX,
} from 'react';
import parse, {
  HTMLReactParserOptions,
  domToReact,
  Element,
  DOMNode,
} from 'html-react-parser';
import Link from 'next/link';
import { mergeDeep } from '../utils/mergeObject';
import styled from 'styled-components';
import { sanitize, Config } from 'isomorphic-dompurify';

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.machineGreen600};
`;

const baseStringConfig: ParseComponents = {
  p: ({ children }) => <>{children?.valueOf()}</>,
  ul: ({ children }) => <>{children?.valueOf()}</>,
  ol: ({ children }) => <>{children?.valueOf()}</>,
  li: ({ children }) => <>{children?.valueOf()}</>,
  strong: ({ children }) => <>{children?.valueOf()}</>,
  em: ({ children }) => <>{children?.valueOf()}</>,
  u: ({ children }) => <>{children?.valueOf()}</>,
  a: ({ children }) => <>{children?.valueOf()}</>,
  br: () => <></>,
};

const defaultSanitizeConfig: Config = {
  USE_PROFILES: {
    html: true,
    mathMl: false,
    svg: true,
    svgFilters: true,
  },
  SANITIZE_NAMED_PROPS: true,
};

export type ParseComponents = {
  [key in keyof ReactHTML]?: ComponentType<ComponentProps<key>>;
};

export interface HookProps {
  parseComponents?: ParseComponents;
  simpleText?: boolean;
}

const defaultParseComponents: ParseComponents = {
  a: ({ href, children, ref, ...rest }) => (
    <StyledLink
      href={href || ''}
      onClick={(e) => {
        e.stopPropagation();
      }}
      {...rest}
    >
      {children}
    </StyledLink>
  ),
};

const useParseHTML = ({ parseComponents, simpleText }: HookProps = {}) => {
  const parseOptions: HTMLReactParserOptions = useMemo(() => {
    const mergedParseComponents = mergeDeep(
      {},
      defaultParseComponents,
      simpleText ? baseStringConfig : {},
      parseComponents || {},
    );
    return {
      replace: (domNode) => {
        const typedDomNode = domNode as Element;
        if (
          typedDomNode &&
          mergedParseComponents[typedDomNode.name as keyof ReactHTML]
        ) {
          const name = typedDomNode.name;
          const attribs = typedDomNode.attribs;
          const children =
            typedDomNode.children?.length > 0
              ? domToReact(typedDomNode.children as DOMNode[], parseOptions)
              : null;
          return createElement(
            // eslint-disable-next-line
            // @ts-ignore TODO: strongly type this
            mergedParseComponents[name as keyof ReactHTML],
            { ...attribs },
            children,
          );
        }
      },
    };
  }, [parseComponents, simpleText]);

  const sanitizeContent = useCallback((content: string, config?: Config) => {
    if (!content) return content;
    return sanitize(content, {
      ...defaultSanitizeConfig,
      ...(config || {}),
      RETURN_DOM: false,
      RETURN_DOM_FRAGMENT: false,
      RETURN_TRUSTED_TYPE: false,
    });
  }, []);

  const parseContentAsString = useCallback(
    (content: string | JSX.Element | JSX.Element[]) => {
      const getTextContent = (elem: ReactElement | string): string => {
        if (!elem) {
          return '';
        }
        if (typeof elem === 'string') {
          return elem?.trim();
        }
        const children = elem.props && elem.props.children;
        if (children && Array.isArray(children)) {
          return children.map(getTextContent).join(' ');
        }
        return getTextContent(children);
      };
      if (!content) {
        return '';
      }
      if (Array.isArray(content)) {
        const stringContent = content?.reduce((acc, curr) => {
          const str = getTextContent(curr)?.trim();
          if (str?.length) {
            acc += ` ${str}${str?.endsWith('.') ? '' : '.'}`;
          }

          return acc;
        }, '');

        return stringContent?.trim();
      }
      return getTextContent(content);
    },
    [],
  );

  const parseContent = useCallback(
    (html?: string, config?: Config) => {
      if (typeof html !== 'string') return undefined;
      const cleanString = sanitizeContent(html, config) as string;
      const result = parse(cleanString, parseOptions);
      return simpleText ? parseContentAsString(result) : result;
    },
    [parseOptions, sanitizeContent, simpleText, parseContentAsString],
  );

  return {
    parseContent,
    sanitizeContent,
  };
};

export default useParseHTML;
