import { styled } from 'styled-components';
import Image from 'next/image';
import { HTMLAttributes } from 'react';
import BaseCarousel from 'styleguide/src/components/Carousel';
import {
  MediaContainer,
  MediaContainerSize,
} from 'styleguide/src/components/Blog';
import { ButtonVariants } from 'styleguide/src/components/Button/Button.styles';

const StyledImage = styled(Image)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledMediaContainer = styled(MediaContainer)`
  width: calc(100% - 80px);

  // width above overwrites base component styles so need to re-add
  @media ${({ theme }) => theme.breakpoints.large} {
    width: 794px; // allows image to overflow parent container
  }
  @media ${({ theme }) => theme.breakpoints.xlarge} {
    width: 886px; // allows image to overflow parent container
  }

  & > div {
    margin: 0 auto;
    aspect-ratio: 1;
    max-height: 355px;
    border-radius: 30px;

    @media ${({ theme }) => theme.breakpoints.medium} {
      aspect-ratio: 6 / 3;
      max-height: 466px;
      border-radius: 50px;
    }
  }
`;

type Props = {
  attributes?: Omit<HTMLAttributes<HTMLElement>, 'color'> & { key: string };
  title: string;
  subtitle?: string;
  slideDuration: number;
  slides: {
    title?: string;
    description?: string;
    video?: string;
    image?: string;
    alt: string;
    callToAction: {
      label: string;
      link: string;
      variant: ButtonVariants;
    }[];
  }[];
};

function SlideMedia(props: { video?: string; image?: string; alt: string }) {
  const { video, image, alt } = props;
  if (video) {
    return (
      <StyledMediaContainer size={MediaContainerSize.large}>
        <video controls={false} autoPlay muted loop>
          <source src={video} type="video/mp4" />
          <source src={video} type="video/webm" />
          Your browser does not support the video tag.
        </video>
      </StyledMediaContainer>
    );
  }
  if (image) {
    return (
      <StyledMediaContainer size={MediaContainerSize.large}>
        <StyledImage src={image} alt={alt} layout="fill" />
      </StyledMediaContainer>
    );
  }

  return null;
}

function Carousel(props: Props) {
  const { attributes, title, subtitle, slideDuration, slides } = props;

  if (!attributes) {
    return null;
  }

  return (
    <div {...attributes}>
      <BaseCarousel
        title={title}
        subTitle={subtitle}
        timeIntervalSeconds={slideDuration}
        slides={slides.map(
          ({ title, description, video, image, alt, callToAction }, index) => ({
            id: `slide-${index}-${attributes.key!}`,
            title,
            description,
            children: <SlideMedia video={video} image={image} alt={alt} />,
            ctas: callToAction?.map((cta) => cta),
          }),
        )}
      />
    </div>
  );
}

export default Carousel;
